<template>
  <div class="no-chip-highlight">
    <!-- PESQUISA SOBRE O GOOGLE WORKSPACE DO CLIENTE -->
    <v-container
      v-if="!answered"
      :class="`d-flex align-center justify-center ${
        isMobile ? 'mt-0' : 'mt-10'
      }`"
    >
      <div class="d-sm-flex align-center justify-center">
        <div
          :class="`${
            isMobile ? 'mr-0' : 'mr-10'
          } d-flex align-center justify-center mt-15`"
        >
          <v-img :src="researchImage" :max-width="imageWidth" />
        </div>
        <div class="max-w-400 text-center mt-15">
          <v-card flat max-width="400">
            <h2 class="text-h4 font-weight-bold mt-5">
              {{ $t("techDiagnosis.workspaceResearch.almostThere") }}
            </h2>
            <h2 class="text-h5">
              {{ $t("techDiagnosis.workspaceResearch.answerQuestionsBellow") }}
            </h2>
            <div class="mt-5">
              <v-select
                v-model="companyGooglePartner.partner_method"
                class="mb-6"
                :items="partner_methods"
                :label="
                  $t('techDiagnosis.workspaceResearch.partnerMethodLabel')
                "
                outlined
                hide-details
                color="primary"
              ></v-select>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mb-6"
                    :label="
                      $t('techDiagnosis.workspaceResearch.renewalDateLabel')
                    "
                    outlined
                    v-model="renewalDate"
                    readonly
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                  </v-text-field>
                </template>
                <v-date-picker v-model="companyGooglePartner.payment_renewal">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t("action.cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.menu.save(companyGooglePartner.payment_renewal)
                    "
                  >
                    {{ $t("action.ok") }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-text-field
                v-model="companyGooglePartner.price_per_license"
                :label="$t('techDiagnosis.workspaceResearch.priceLicenseLabel')"
                placeholder="0,00"
                outlined
                type="number"
                color="primary"
                :rules="[rules.max, rules.min]"
                :max="1000"
                :min="0"
              ></v-text-field>
            </div>
            <div class="d-flex align-center justify-space-between">
              <v-btn
                @click="skipGoogleResearch"
                small
                text
                class="text-none text-caption"
                >{{ $t("action.skip") }}</v-btn
              >
              <v-btn
                :loading="loadingGoogleResearch"
                elevation="0"
                class="text-none white--text"
                :color="secondaryColor"
                :disabled="!validCompanyGooglePartner"
                @click="saveCompanyGooglePartner"
              >
                {{ $t("action.continue") }}
                <v-icon right dark class="arrow-left-transition">
                  mdi-arrow-right-thin
                </v-icon>
              </v-btn>
            </div>
          </v-card>
        </div>
      </div>
    </v-container>
    <!-- DIAGNOSTICO -->
    <transition appear name="fade">
      <v-container v-show="answered">
        <!-- BANNER RESULTADO DO DIAGNOSTICO -->
        <v-card :loading="loadingChecklist" outlined color="primary">
          <v-row no-gutters>
            <v-col class="d-flex flex-column">
              <div class="white--text pa-5">
                <h1 class="font-weight-regular">
                  {{ $t("techDiagnosis.title") }}
                </h1>
              </div>
              <div class="d-flex align-center py-10">
                <v-progress-circular
                  v-if="loadingChecklist"
                  indeterminate
                  color="primary"
                  class="mx-3"
                ></v-progress-circular>
                <h2
                  v-if="loadingChecklist"
                  class="white--text font-weight-light"
                >
                  {{ $t("techDiagnosis.analyzingCompany") }}
                </h2>
                <div
                  v-else-if="
                    overallDiagonisticStatus === 'success' && !loadingChecklist
                  "
                  class="d-flex align-center"
                >
                  <v-icon
                    v-if="!loadingChecklist"
                    size="40"
                    class="mx-3"
                    v-text="'mdi-check-circle-outlined'"
                    :color="overallDiagonisticStatus"
                  >
                  </v-icon>
                  <h2 class="white--text font-weight-light">
                    {{ $t("techDiagnosis.companyInOrder") }}
                  </h2>
                </div>
                <div
                  v-else-if="
                    overallDiagonisticStatus === 'error' && !loadingChecklist
                  "
                  class="d-flex align-center"
                >
                  <v-icon
                    v-if="!loadingChecklist"
                    size="40"
                    class="mx-3"
                    v-text="'mdi-alert-circle-outline'"
                    :color="overallDiagonisticStatus"
                  >
                  </v-icon>
                  <h2 class="white--text font-weight-light">
                    {{ $t("techDiagnosis.companyHasPointsOfImprovement") }}
                  </h2>
                </div>
                <div
                  v-else-if="
                    overallDiagonisticStatus === 'warning' && !loadingChecklist
                  "
                  class="d-flex align-center"
                >
                  <v-icon
                    v-if="!loadingChecklist"
                    size="40"
                    class="mx-3"
                    v-text="'mdi-alert-circle-outline'"
                    :color="overallDiagonisticStatus"
                  >
                  </v-icon>
                  <h2 class="white--text font-weight-light">
                    {{ $t("techDiagnosis.companyHasPointsOfAttention") }}
                  </h2>
                </div>
                <h2 v-else class="white--text font-weight-light">
                  {{ $t("techDiagnosis.getAnAnalysis") }}
                </h2>
              </div>
            </v-col>
            <v-col class="d-flex align-end justify-end">
              <v-img
                :src="
                  require(`@/assets/images/imagem_workspace_checklist_primary.png`)
                "
                max-height="240"
                max-width="400"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-divider></v-divider>

        <!-- DADOS DO CHECKLIST -->
        <v-row class="mt-3 mb-10" no-gutters>
          <v-col>
            <v-subheader
              class="mt-5 mb-3 font-weight-bold py-3 text-h6 black--text"
              style="height: fit-content"
            >
              {{ $t("techDiagnosis.sessions.generalInfo") }}
            </v-subheader>

            <v-card outlined>
              <v-progress-linear
                v-if="loadingChecklist"
                indeterminate
                color="primary"
              ></v-progress-linear>
              <v-row
                justify="center"
                no-gutters
                :class="$vuetify.breakpoint.mdAndUp ? 'flex-nowrap' : ''"
              >
                <v-col
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'flex-grow-0 flex-shrink-1'
                      : ''
                  "
                  xs="12"
                  sm="12"
                  md="3"
                  :style="`min-width: 170px; ${
                    $vuetify.breakpoint.mdAndUp
                      ? 'border-right: thin solid rgba(0, 0, 0, .12);'
                      : ''
                  }`"
                >
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                      <v-avatar
                        class="ms-6 ma-3"
                        color="grey lighten-3"
                        size="30"
                      >
                        <v-icon
                          v-text="'mdi-google-cloud'"
                          color="primary"
                        ></v-icon>
                      </v-avatar>
                      <p class="ma-0 font-weight-bold">
                        {{ $t("techDiagnosis.workspaceInfo.title") }}
                      </p>
                    </div>
                    <div class="d-flex">
                      <v-tooltip
                        v-if="!validCompanyGooglePartner"
                        bottom
                        color="error"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="error"
                            v-text="'mdi-alert'"
                            class="ms-3"
                          />
                        </template>
                        <div style="max-width: 270px">
                          {{ $t("techDiagnosis.workspaceInfo.missingData") }}
                        </div>
                      </v-tooltip>

                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="ma-3"
                            v-text="'mdi-dots-horizontal'"
                            v-bind="attrs"
                            v-on="on"
                          ></v-icon>
                        </template>
                        <v-list>
                          <v-list-item @click="editResearch">
                            <v-list-item-title>
                              <a class="text-decoration-none">
                                {{
                                  validCompanyGooglePartner
                                    ? $t("techDiagnosis.workspaceInfo.editData")
                                    : $t("techDiagnosis.workspaceInfo.fillData")
                                }}
                              </a>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-title>
                              <a
                                href="https://admin.google.com/"
                                target="_blank"
                                class="text-decoration-none"
                                >{{
                                  $t(
                                    "techDiagnosis.workspaceInfo.goToGoogleAdmin"
                                  )
                                }}
                              </a>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>

                  <div
                    :class="`d-flex ${
                      $vuetify.breakpoint.mdAndUp
                        ? 'flex-column justify-center'
                        : 'justify-space-between'
                    } align-start mx-5 my-2`"
                    style="min-width: 170px"
                  >
                    <p>
                      <span class="font-weight-medium">
                        {{
                          $t("techDiagnosis.workspaceInfo.partnerMethod")
                        }} </span
                      ><br />
                      {{ companyGooglePartner.partner_method || "--" }}
                    </p>
                    <p>
                      <span class="font-weight-medium">
                        {{
                          $t("techDiagnosis.workspaceInfo.renewalDate")
                        }} </span
                      ><br />
                      {{
                        formatDateIntoStringDayMonthYear(
                          companyGooglePartner.payment_renewal
                        ) || "--"
                      }}
                    </p>
                    <p>
                      <span class="font-weight-medium">
                        {{
                          $t("techDiagnosis.workspaceInfo.activeLicenses")
                        }} </span
                      ><br />
                      {{ activeLicenses }}
                    </p>
                    <p>
                      <span class="font-weight-medium">
                        {{
                          $t("techDiagnosis.workspaceInfo.pricePerLicense")
                        }} </span
                      ><br />
                      {{
                        $t("techDiagnosis.workspaceInfo.priceResult", {
                          price: companyGooglePartner.price_per_license || "--",
                        })
                      }}
                    </p>
                  </div>
                </v-col>
                <v-col
                  :class="
                    $vuetify.breakpoint.mdAndUp
                      ? 'flex-grow-1 flex-shrink-0'
                      : ''
                  "
                  xs="12"
                  sm="12"
                  md="9"
                  style="min-width: 400px"
                >
                  <v-expansion-panels
                    :value="0"
                    :readonly="$vuetify.breakpoint.mdAndUp"
                    flat
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        :hide-actions="$vuetify.breakpoint.mdAndUp"
                      >
                        <div class="d-flex justify-space-between align-center">
                          <div class="d-flex align-center">
                            <v-avatar
                              class="me-3"
                              color="grey lighten-3"
                              size="30"
                            >
                              <v-icon
                                v-text="'mdi-account-multiple'"
                                color="primary"
                              ></v-icon>
                            </v-avatar>
                            <p class="ma-0 font-weight-bold">
                              {{ $t("techDiagnosis.usersGraphics.title") }}
                            </p>
                          </div>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="no-expansion-padding">
                        <template #default="{ open }">
                          <v-row v-show="open" no-gutters>
                            <v-col
                              :xs="12"
                              :sm="6"
                              align="center"
                              justify="center"
                            >
                              <DonutChart
                                style="max-width: 100%"
                                :loading="loadingChecklist"
                                :name="'activeUsers'"
                                :config="dataForUsersDonutChart"
                              />
                            </v-col>
                            <v-col
                              :xs="12"
                              :sm="6"
                              align="center"
                              justify="center"
                            >
                              <DonutChart
                                style="max-width: 100%"
                                :loading="loadingChecklist"
                                :name="'groupUsers'"
                                :config="dataForGroupUsersDonutChart"
                              />
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card>

            <v-subheader
              class="mt-5 font-weight-bold py-3 text-h6 black--text"
              style="height: fit-content"
            >
              {{ $t("techDiagnosis.sessions.economyInfo") }}
            </v-subheader>

            <!-- USUÁRIOS INATIVOS -->
            <DiagnosticRowItem
              class="mt-3"
              icon="mdi-account-alert"
              :title="$t('techDiagnosis.inactiveUsers.title')"
              :diagnostic="inactive"
              :tooltipText="
                $t('techDiagnosis.inactiveUsers.tooltipText', {
                  researchAlert: verifyLicensesPriceText,
                })
              "
              :headers="headers"
              redirect-to-user
              :loading="loadingChecklist"
              :licenses-price="companyGooglePartner.price_per_license"
              :invalid-google-research="!validCompanyGooglePartner"
              :kindPlural="
                inactive.count > 1
                  ? $t('techDiagnosis.kindUsers')
                  : $t('techDiagnosis.kindUser')
              "
            >
              <template v-slot:top>
                <div class="d-flex justify-space-between mb-2">
                  <div>
                    <v-chip
                      class="mx-1 font-weight-bold"
                      outlined
                      @click="selectedFilter = 30"
                      :input-value="selectedFilter === 30"
                      filter
                      small
                      >{{
                        $t("techDiagnosis.inactiveUsers.inactiveDays", {
                          numberOfDays: "30",
                        })
                      }}</v-chip
                    >
                    <v-chip
                      class="mx-1 font-weight-bold"
                      outlined
                      @click="selectedFilter = 60"
                      :input-value="selectedFilter === 60"
                      filter
                      small
                      >{{
                        $t("techDiagnosis.inactiveUsers.inactiveDays", {
                          numberOfDays: "60",
                        })
                      }}</v-chip
                    >

                    <v-chip
                      class="mx-1 font-weight-bold"
                      outlined
                      @click="selectedFilter = 90"
                      :input-value="selectedFilter === 90"
                      filter
                      small
                      >{{
                        $t("techDiagnosis.inactiveUsers.inactiveDays", {
                          numberOfDays: "90",
                        })
                      }}</v-chip
                    >
                  </div>
                  <div>
                    <v-btn
                      elevation="0"
                      class="text-none white--text mx-1"
                      :color="secondaryColor"
                      dark
                      small
                      href="https://admin.google.com/ac/users"
                      target="_blank"
                    >
                      {{ $t("techDiagnosis.goToGoogleWorkspace") }}
                      <v-icon right dark class="arrow-left-transition">
                        mdi-arrow-right-thin
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
            </DiagnosticRowItem>

            <!-- USUÁRIOS SUSPENSOS -->
            <DiagnosticRowItem
              class="mt-3"
              icon="mdi-account-cancel"
              :title="$t('techDiagnosis.suspendedUsers.title')"
              :diagnostic="suspended"
              :tooltipText="
                $t('techDiagnosis.suspendedUsers.tooltipText', {
                  researchAlert: verifyLicensesPriceText,
                })
              "
              :headers="headersSuspended"
              :loading="loadingChecklist"
              :licenses-price="companyGooglePartner.price_per_license"
              :invalid-google-research="!validCompanyGooglePartner"
              :kindPlural="
                suspended.count > 1
                  ? $t('techDiagnosis.kindUsers')
                  : $t('techDiagnosis.kindUser')
              "
            >
              <template v-slot:top>
                <div class="d-flex justify-end mb-2">
                  <v-btn
                    elevation="0"
                    class="text-none white--text mx-1"
                    :color="secondaryColor"
                    dark
                    small
                    href="https://admin.google.com/ac/users"
                    target="_blank"
                  >
                    {{ $t("techDiagnosis.goToGoogleWorkspace") }}
                    <v-icon right dark class="arrow-left-transition">
                      mdi-arrow-right-thin
                    </v-icon>
                  </v-btn>
                </div>
              </template>
            </DiagnosticRowItem>

            <!-- USUÁRIOS QUE PODERIAM SER GRUPOS -->
            <DiagnosticRowItem
              class="mt-3"
              icon="mdi-account-group"
              :title="$t('techDiagnosis.couldBeGroupsUsers.title')"
              :diagnostic="should_be_groups"
              :tooltipText="$t('techDiagnosis.couldBeGroupsUsers.tooltipText')"
              :headers="headers"
              redirect-to-user
              :loading="loadingChecklist"
              :licenses-price="companyGooglePartner.price_per_license"
              :invalid-google-research="!validCompanyGooglePartner"
              :kindPlural="
                should_be_groups.count > 1
                  ? $t('techDiagnosis.kindUsers')
                  : $t('techDiagnosis.kindUser')
              "
            >
              <template v-slot:top>
                <div class="d-flex justify-end mb-2">
                  <v-btn
                    v-if="hasConectaSupport"
                    elevation="0"
                    class="text-none white--text mx-1"
                    :color="secondaryColor"
                    dark
                    small
                    outlined
                    @click="openSupportChat"
                    >{{ $t("techDiagnosis.chatSupport") }}
                    <v-icon right dark> mdi-face-agent </v-icon>
                  </v-btn>

                  <v-btn
                    elevation="0"
                    class="text-none white--text mx-1"
                    :color="secondaryColor"
                    dark
                    small
                    href="https://admin.google.com/ac/groups"
                    target="_blank"
                    >{{ $t("techDiagnosis.goToGoogleWorkspace") }}
                    <v-icon right dark class="arrow-left-transition">
                      mdi-arrow-right-thin
                    </v-icon>
                  </v-btn>
                </div>
              </template>
            </DiagnosticRowItem>

            <!-- USUÁRIOS INATIVOS COM APLICAÇÔES ATRIBUIDAS -->
            <DiagnosticRowItem
              class="mt-3"
              icon="mdi-account-card-outline"
              :title="$t('techDiagnosis.inactiveAppsUsers.title')"
              :diagnostic="inactive_user_apps"
              :tooltipText="$t('techDiagnosis.inactiveAppsUsers.tooltipText')"
              :headers="headersInventory"
              redirect-to-user
              :loading="loadingChecklist"
              :isApp="true"
              :kindPlural="
                inactive_user_apps.count > 1
                  ? $t('techDiagnosis.inactiveAppsUsers.apps')
                  : $t('techDiagnosis.inactiveAppsUsers.app')
              "
              :licenses-price="companyGooglePartner.price_per_license"
            >
              <template v-slot:top>
                <div class="d-flex justify-end mb-2">
                  <v-btn
                    elevation="0"
                    @click="goToInventory"
                    class="text-none mx-1"
                    :color="secondaryColor"
                    dark
                    small
                    >{{
                      $t("techDiagnosis.inactiveAppsUsers.accessInventory")
                    }}
                    <v-icon right dark class="arrow-left-transition">
                      mdi-arrow-right-thin
                    </v-icon></v-btn
                  >
                </div>
              </template>
            </DiagnosticRowItem>

            <v-subheader
              class="mt-5 text-subtitle-2"
              style="
                height: fit-content;
                color: var(--v-warning-base) !important;
                opacity: 0.9;
              "
            >
              <v-spacer />

              <v-tooltip
                bottom
                :color="licensesTotalPrice != '0.00' ? 'warning' : 'success'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="white--text ml-4"
                    :color="
                      licensesTotalPrice != '0.00' ? 'warning' : 'success'
                    "
                    outlined
                  >
                    {{
                      $t("techDiagnosis.totalPrice", {
                        price: licensesTotalPrice,
                      })
                    }}
                  </v-chip>
                </template>
                <div style="max-width: 270px">
                  {{
                    $t("techDiagnosis.totalPriceTooltip", {
                      price: licensesTotalPrice,
                    })
                  }}
                </div>
              </v-tooltip>
            </v-subheader>

            <v-subheader
              class="font-weight-bold py-3 text-h6 black--text"
              style="height: fit-content"
            >
              {{ $t("techDiagnosis.sessions.emails") }}
            </v-subheader>

            <!-- USUÁRIOS SEM ASSINATURA -->
            <DiagnosticRowItem
              class="mt-3"
              icon="mdi-email-alert"
              :title="$t('techDiagnosis.withoutSignatureUsers.title')"
              :diagnostic="withoutSignature"
              :tooltipText="
                $t('techDiagnosis.withoutSignatureUsers.tooltipText')
              "
              :headers="headers"
              redirect-to-user
              :loading="loadingChecklist"
              :kindPlural="
                withoutSignature.count > 1
                  ? $t('techDiagnosis.kindUsers')
                  : $t('techDiagnosis.kindUser')
              "
            >
              <template v-slot:top>
                <div class="d-flex justify-end mb-2">
                  <v-btn
                    elevation="0"
                    @click="goToConectaSign"
                    class="text-none mx-1"
                    :color="secondaryColor"
                    dark
                    small
                  >
                    {{
                      $t("techDiagnosis.withoutSignatureUsers.goToSignature")
                    }}
                    <v-icon right dark class="arrow-left-transition">
                      mdi-arrow-right-thin
                    </v-icon></v-btn
                  >
                </div>
              </template>
            </DiagnosticRowItem>

            <!-- DNS Validacao -->
            <DNS class="mt-3" @update-dns-diagnostic="updateDnsDiagnostic" />

            <v-subheader
              class="mt-5 black--text font-weight-bold py-3 text-h6"
              style="height: fit-content"
            >
              {{ $t("techDiagnosis.sessions.security") }}
            </v-subheader>

            <!-- USUÀRIOS SEM 2FA -->
            <DiagnosticRowItem
              class="mt-3"
              icon="mdi-account-alert"
              :title="$t('techDiagnosis.without2faUsers.title')"
              :diagnostic="users_without_2fa"
              :tooltipText="$t('techDiagnosis.without2faUsers.tooltipText')"
              :headers="headers"
              redirect-to-user
              :loading="loadingChecklist"
              :kindPlural="
                users_without_2fa.count > 1
                  ? $t('techDiagnosis.kindUsers')
                  : $t('techDiagnosis.kindUser')
              "
            >
              <template v-slot:top>
                <div class="d-flex justify-end mb-2">
                  <v-btn
                    elevation="0"
                    class="text-none mx-1 white--text"
                    :color="secondaryColor"
                    dark
                    small
                    href="https://confluence.external-share.com/content/95280/ativação_da_autenticação_de_dois_fatores_no_google_workspace"
                    target="_blank"
                    >{{ $t("action.knowMore") }}
                    <v-icon right dark class="arrow-left-transition">
                      mdi-arrow-right-thin
                    </v-icon></v-btn
                  >
                </div>
              </template>
            </DiagnosticRowItem>

            <!-- RELATÓRIO DO GOOGLE DRIVE -->
            <v-expansion-panels readonly flat class="outlined mt-3">
              <v-expansion-panel @click.stop="goToDriveReport()">
                <v-expansion-panel-header>
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                      <v-avatar color="grey lighten-3" size="30">
                        <Logo product="conecta_control" />
                      </v-avatar>
                      <p class="ma-0 ml-3 font-weight-bold">
                        {{ $t("techDiagnosis.googleDriveReports.title") }}
                      </p>
                    </div>
                    <div>
                      <v-tooltip bottom color="warning">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="warning"
                            v-text="'mdi-alert-circle-outline'"
                          />
                        </template>
                        <div style="max-width: 270px">
                          {{
                            $t("techDiagnosis.googleDriveReports.tooltipText")
                          }}
                        </div>
                      </v-tooltip>
                      <v-chip
                        small
                        class="white--text mx-4 cursor"
                        color="warning"
                        outlined
                      >
                        {{ $t("techDiagnosis.googleDriveReports.files") }}
                      </v-chip>
                    </div>
                  </div>
                  <template v-slot:actions>
                    <v-icon v-text="'mdi-open-in-new'" />
                  </template>
                </v-expansion-panel-header>
              </v-expansion-panel>
            </v-expansion-panels>

            <!-- GOOGLE TAKEOUT AND CHECKUP PRIVACY -->
            <v-expansion-panels
              readonly
              flat
              class="outlined mt-3"
              v-for="(option, i) in expansionOptions"
              :key="i"
            >
              <v-expansion-panel @click.stop="openLink(option.link)">
                <v-expansion-panel-header>
                  <div class="d-flex justify-space-between align-center">
                    <div class="d-flex align-center">
                      <v-avatar color="grey lighten-3" size="30">
                        <v-icon color="primary">{{ option.icon }}</v-icon>
                      </v-avatar>
                      <p class="ma-0 ml-3 font-weight-bold">
                        {{ $t(option.title) }}
                      </p>
                    </div>
                    <div>
                      <v-tooltip bottom color="warning">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="warning"
                            v-text="'mdi-alert-circle-outline'"
                          />
                        </template>
                        <div style="max-width: 270px">
                          {{ $t(option.tooltip) }}
                        </div>
                      </v-tooltip>
                      <v-chip
                        small
                        class="white--text mx-4 cursor"
                        color="warning"
                        outlined
                      >
                        {{ $t(option.chipText) }}
                      </v-chip>
                    </div>
                  </div>
                  <template v-slot:actions>
                    <v-icon v-text="'mdi-open-in-new'" />
                  </template>
                </v-expansion-panel-header>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
import DNS from "@/components/sign/general/DNS.vue";
import DiagnosticRowItem from "@/components/general/DiagnosticRowItem.vue";
import DonutChart from "@/components/general/DonutChart.vue";
import {
  goToDriveReport,
  openLink,
  formatDateIntoStringYearMonthDay,
  formatDateIntoStringDayMonthYear,
  formatDateWithDayOfWeek,
} from "@/helpers/services/utils";
import { BAD, WARNING, OK } from "@/helpers/variables/diagnostic";
import { getAnalytics, logEvent } from "firebase/analytics";

export default {
  name: "WorkspaceChecklist",
  components: { DNS, DiagnosticRowItem, DonutChart },
  data() {
    return {
      selectedFilter: 30, // 30, 60, ou 90
      loadingChecklist: false,
      checklistDiagnostic: null,
      companyGooglePartner: {
        partner_method: "",
        payment_renewal: "",
        price_per_license: null,
      },
      menu: false,
      answered: true,
      isEditing: false,
      backupCompanyGooglePartner: {},
      researchImage: require("@/assets/images/checklist_research.png"),
      loadingGoogleResearch: false,
      takeoutLink:
        "https://confluence.external-share.com/content/93638/seus_usuários_possuem_acesso_ao_google_takeout_",
      businessPriceLicense: 105,
    };
  },
  computed: {
    ...mapGetters([
      "secondaryColor",
      "users",
      "company",
      "currentUser",
      "hasConectaSupport",
      "token",
    ]),

    partner_methods() {
      return [
        this.$t("techDiagnosis.workspaceResearch.authorizedPartner"),
        this.$t("techDiagnosis.workspaceResearch.directWithGoogle"),
      ];
    },

    rules() {
      return {
        max: (v) =>
          v <= 1000 || this.$t("techDiagnosis.workspaceResearch.ruleMaxValue"),
        min: (v) =>
          (!!v && v > 0) ||
          this.$t("techDiagnosis.workspaceResearch.ruleMinValue"),
      };
    },

    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },

    imageWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "300px" : "450px";
    },

    activeLicenses() {
      return this.checklistDiagnostic?.active_licenses || 0;
    },
    checkupLink() {
      const lang =
        this.company.language == "pt-br" ? "pt_BR" : this.company.language;
      return `https://myaccount.google.com/privacycheckup?utm_source=pp&utm_medium=Promo-in-product&utm_campaign=pp_intro&hl=${lang}`;
    },

    expansionOptions() {
      return [
        {
          title: "techDiagnosis.googleTakeout.title",
          icon: "mdi-security",
          link: this.takeoutLink,
          tooltip: "techDiagnosis.googleTakeout.tooltipText",
          chipText: "techDiagnosis.googleTakeout.knowHowToVerify",
        },
        {
          title: "techDiagnosis.privacyCheckup.title",
          icon: "mdi-shield-account",
          link: this.checkupLink,
          tooltip: "techDiagnosis.privacyCheckup.tooltipText",
          chipText: "techDiagnosis.privacyCheckup.knowHowToVerify",
        },
      ];
    },

    renewalDate() {
      if (this.companyGooglePartner.payment_renewal) {
        return formatDateWithDayOfWeek(
          this.companyGooglePartner.payment_renewal
        );
      }
      return "";
    },

    validCompanyGooglePartner() {
      for (const anwser in this.companyGooglePartner) {
        if (!this.companyGooglePartner[anwser]) {
          return false;
        }
      }
      if (
        Number(this.companyGooglePartner.price_per_license) > 1000 ||
        Number(this.companyGooglePartner.price_per_license) <= 0
      ) {
        return false;
      }
      return true;
    },

    withoutSignature() {
      return this.checklistDiagnostic?.without_signature || 0;
    },

    suspended() {
      return this.checklistDiagnostic?.suspended || 0;
    },

    inactive() {
      return this.checklistDiagnostic?.inactive || 0;
    },

    should_be_groups() {
      return this.checklistDiagnostic?.should_be_groups || 0;
    },

    domains() {
      return this.checklistDiagnostic?.should_be_groups || 0;
    },

    inactive_user_apps() {
      return this.checklistDiagnostic?.inactive_user_apps || 0;
    },

    users_without_2fa() {
      return this.checklistDiagnostic?.users_without_2fa || 0;
    },

    verifyLicensesPriceText() {
      return this.companyGooglePartner.price_per_license
        ? ""
        : this.$t("techDiagnosis.fillResearchValues");
    },

    dataForUsersDonutChart() {
      let inactive = {
        label: this.$t("techDiagnosis.usersGraphics.inactive"),
        count: this.inactive.count,
      };

      let suspended = {
        label: this.$t("techDiagnosis.usersGraphics.suspended"),
        count: this.suspended.count,
      };

      let active = {
        label: this.$t("techDiagnosis.usersGraphics.active"),
        count: Number(this.activeLicenses || 1) - Number(this.inactive.count),
      };

      let config = {
        type: "doughnut",
        data: {
          labels: [inactive.label, suspended.label, active.label],
          datasets: [
            {
              backgroundColor: ["#808181", "#311F50", "rgb(148, 62, 224)"],
              borderColor: "rgb(255, 255, 255)",
              data: [inactive.count, suspended.count, active.count],
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              color: "white",
            },
          },
          legend: {
            position: "right",
            align: "center",
          },
        },
      };

      return config;
    },

    dataForGroupUsersDonutChart() {
      let users = {
        label: this.$t("techDiagnosis.usersGraphics.individual"),
        count:
          Number(this.activeLicenses || 0) -
          Number(this.should_be_groups.count),
      };

      let should_be_groups = {
        label: this.$t("techDiagnosis.usersGraphics.couldBeGroups"),
        count: this.should_be_groups.count,
      };

      let config = {
        type: "doughnut",
        data: {
          labels: [users.label, should_be_groups.label],
          datasets: [
            {
              backgroundColor: ["rgb(163, 73, 164)", "#7444E5"],
              borderColor: "rgb(255, 255, 255)",
              data: [users.count, should_be_groups.count],
            },
          ],
        },
        options: {
          plugins: {
            datalabels: {
              color: "white",
            },
          },
          legend: {
            position: "right",
            align: "center",
          },
        },
      };

      return config;
    },

    overallDiagonisticStatus() {
      const statuses = Object.values(this.checklistDiagnostic || {}).map(
        (diagnostic) => diagnostic.status
      );
      if (!this.loadingChecklist && statuses.includes(BAD)) {
        return "warning";
      } else if (!this.loadingChecklist && statuses.includes(WARNING)) {
        return "warning";
      } else if (!this.loadingChecklist && statuses.includes(OK)) {
        return "success";
      } else {
        return "primary";
      }
    },

    filteredInactiveUsers() {
      const current_date = moment();
      const inactive_period = moment.duration(this.selectedFilter, "days");

      const date_format = "YYYY-MM-DD HH:mm:ss";

      const filtered_users = this.inactive._users.filter((user) => {
        const last_login = moment(user.last_login, date_format);
        const duration = moment.duration(current_date.diff(last_login));
        return duration.as("days") > inactive_period.asDays();
      });
      return filtered_users;
    },

    headers() {
      return [
        {
          text: this.$t("tableHeaders.users"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("tableHeaders.department"),
          align: "start",
          sortable: false,
          value: "departament",
        },
        {
          text: this.$t("tableHeaders.lastLogin"),
          align: "start",
          sortable: false,
          value: "last_login",
        },
      ];
    },

    headersSuspended() {
      return [
        {
          text: this.$t("tableHeaders.users"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("tableHeaders.lastLogin"),
          align: "start",
          sortable: false,
          value: "last_login",
        },
      ];
    },

    headersInventory() {
      return [
        {
          text: this.$t("tableHeaders.apps"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("tableHeaders.users"),
          align: "start",
          sortable: false,
          value: "users_apps",
        },
      ];
    },

    licensesTotalPrice() {
      const count = [
        this.inactive,
        this.suspended,
        this.should_be_groups,
        this.inactive_user_apps,
      ].reduce((total, diagnostic) => total + (diagnostic?.count || 0), 0);
      const licensesPrice = this.companyGooglePartner.price_per_license;
      if (licensesPrice) {
        let totalPrice = count * parseFloat(licensesPrice);
        return totalPrice.toFixed(2);
      } else {
        let totalPrice = count * this.businessPriceLicense;
        return totalPrice.toFixed(2);
      }
    },
  },
  watch: {
    checklistDiagnostic() {
      this.updateInactiveUsers();
    },
    selectedFilter() {
      this.updateInactiveUsers();
    },
    users() {
      this.updateInactiveUsers();
    },
  },
  methods: {
    goToDriveReport,
    openLink,
    formatDateWithDayOfWeek,
    formatDateIntoStringDayMonthYear,

    ...mapActions(["setSnackBar", "verifyHubspotToken"]),

    ...mapMutations(["setCompany"]),

    editResearch() {
      this.answered = false;
      if (this.validCompanyGooglePartner) {
        this.backupCompanyGooglePartner = { ...this.companyGooglePartner };
        this.isEditing = true;
      }
    },

    saveCompanyGooglePartner() {
      this.loadingGoogleResearch = true;

      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const user = this.currentUser.email;
      const { partner_method, payment_renewal, price_per_license } =
        this.companyGooglePartner;

      logEvent(analytics, "research_workspace", {
        main_domain,
        user,
        date: moment().format("DD/MM/YYYY"),
        partner_method,
        payment_renewal,
        price_per_license,
      });

      let url = `${process.env.VUE_APP_API_BASE_URL}/company/company-google-partner`;

      this.companyGooglePartner.price_per_license = parseFloat(
        this.companyGooglePartner.price_per_license
      );

      this.companyGooglePartner.price_per_license =
        this.companyGooglePartner.price_per_license.toFixed(2);

      let payload = { ...this.companyGooglePartner };

      payload.payment_renewal = new Date(
        this.companyGooglePartner.payment_renewal
      );

      const headers = { Authorization: this.token };

      return this.$axios.put(url, payload, { headers }).then(({ data }) => {
        this.setCompany(data);
        this.answered = true;
        this.loadingGoogleResearch = false;
      });
    },

    skipGoogleResearch() {
      this.answered = true;

      if (!this.isEditing) {
        this.companyGooglePartner = {
          partner_method: "",
          payment_renewal: "",
          price_per_license: null,
        };

        const analytics = getAnalytics();
        const { main_domain } = this.company;
        const user = this.currentUser.email;

        logEvent(analytics, "skiped_company_google_partner_research", {
          main_domain,
          user,
          date: moment().format("DD/MM/YYYY"),
        });
      } else {
        this.companyGooglePartner = { ...this.backupCompanyGooglePartner };
      }
    },

    requestChecklistDiagnostic() {
      this.loadingChecklist = true;
      const url =
        process.env.VUE_APP_API_BASE_URL + "/company/workspace-checklist";
      const headers = { Authorization: this.token };
      return this.$axios
        .get(url, { headers })
        .then(({ data }) => {
          this.checklistDiagnostic = {
            ...(this.checklistDiagnostic || {}),
            ...data,
          };
        })
        .catch((error) => {
          console.error("requestChecklistDiagnostic(): ", error);
          this.setSnackBar({
            message: `Não foi possível obter o diagnóstico para sua empresa.`,
            show: true,
            color: "error",
            timeout: 8000,
          });
        })
        .finally(() => (this.loadingChecklist = false));
    },

    updateCompanyGooglePartner() {
      const {
        google_partner_method,
        google_payment_renewal,
        google_price_per_license,
      } = this.company;

      let renewal_date = new Date(google_payment_renewal);
      let formated_renewal_date =
        formatDateIntoStringYearMonthDay(renewal_date);

      if (
        google_partner_method &&
        google_payment_renewal &&
        google_price_per_license
      ) {
        this.companyGooglePartner.partner_method = google_partner_method;
        this.companyGooglePartner.payment_renewal = formated_renewal_date;
        this.companyGooglePartner.price_per_license = String(
          google_price_per_license
        );
      } else {
        this.answered = false;
      }
    },

    goToCompanyProfile() {
      this.$router.push({
        path: "/settings/profile",
      });
    },

    goToConectaSign() {
      this.$router.push({
        path: "/sign/users/apply",
      });
    },

    goToInventory() {
      this.$router.push({
        path: "/inventory",
      });
    },

    updateInactiveUsers() {
      if (this.inactive) {
        if (!this.inactive._users) {
          this.inactive._users = this.inactive.users.slice();
        }
        this.inactive.users = this.filteredInactiveUsers;
      }
    },

    async openSupportChat() {
      await this.verifyHubspotToken();
    },

    updateDnsDiagnostic(domains_quality) {
      this.checklistDiagnostic = {
        ...(this.checklistDiagnostic || {}),
        domains_quality,
      };
    },
  },
  beforeMount() {
    this.requestChecklistDiagnostic();
    this.updateCompanyGooglePartner();
  },
};
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 1s;
}

.fade-enter {
  opacity: 0;
}
</style>

<style>
.no-expansion-padding > .v-expansion-panel-content__wrap {
  padding: 0 0 16px !important;
}
.no-chip-highlight .v-chip:hover:before {
  opacity: 0 !important;
}
.cursor {
  cursor: pointer;
}
</style>
